import React from 'react';
import { Constants } from './Constant';
import '../../Styles/styles.css';
import '../DataCollect.css';

function HoursOfOperation(props) {
    const { handler } = props;
    return (
        <>
            <div>
                <br />
                <hr />
                <h2>Hours of operation</h2>
                <p>If your shop is closed on one or more of the days, please enter “Closed” in both fields.</p>
                {
                    Constants.weeks.map((elem, index) => {
                        return (
                            <div key={'wrap' + index} className='main-div'>
                                <div className="label-margin aspect-ratio soe-label-name" >{elem}</div>
                                <div className="label-margin soe-start" > <select className="column-ten-input-select" key={'start' + index} onChange={(event) => { handler(elem.toLowerCase(), 'from', event.target.value) }} name={elem+' start'}>
                                    {
                                        Constants.hoursOfOperation.map(el => <option key={'op1' + el}>{el}</option>)
                                    }
                                </select>
                                </div>
                                <div className="label-margin soe-to" >  <label><b>To</b></label>  </div>
                                <div className="label-margin soe-end" >  <select className="column-ten-input-select"  key={'end' + index} onChange={(event) => { handler(elem.toLowerCase(), 'to', event.target.value) }} name={elem+' end'}>
                                    {
                                        Constants.hoursOfOperation.map(el => <option key={'op2' + el}>{el}</option>)
                                    }
                                </select>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    );
}

export default HoursOfOperation