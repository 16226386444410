import React from "react";
import '../../Styles/styles.css'

const Checkbox = ({ id, type, name, handleRadioBtnClick, isChecked , classes }) => {
  return (
    <label className={classes}> 
    <span> {name}</span>
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleRadioBtnClick}
        checked={isChecked}
      />
      <span className="checkmark"></span>
      
    </label>

  );
};

export default Checkbox;
