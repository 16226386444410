import React from 'react';
import '../../Styles/styles.css';

function AboutThisStore(props) {
    const { handler } = props;
    return (
        <>
            <div>
                <br />
                <hr />
                <h2>About this store:</h2>
                <textarea className='column-hundred' type="text" placeholder="About This store" name="aboutthestore" onChange={(event) => { handler('aboutStore', event.target.value) }} />

            </div>
        </>
    )
}

export default AboutThisStore