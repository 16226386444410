import React from 'react';
import Checkbox from "./Checkbox";
import '../../Styles/styles.css';

function VehicleTypeWorked(props) {

    const { list, handleRadioBtnClick, isCheck, } = props;
    const catalog = list.map(({ id, name }) => {
        return (
            <>
                <Checkbox
                    key={id.toString()}
                    type="checkbox"
                    name={name}
                    id={id}
                    handleRadioBtnClick={handleRadioBtnClick}
                    isChecked={isCheck.includes(id)}
                    classes={"container column"}
                />
            </>
        );
    });
    return (
        <>
            <div>
                <br />
                <hr />
                <h2>Vehicle type worked on</h2>
                <h3>Check all that apply</h3>
                <div className="row">
                    {catalog}
                </div>

            </div>
        </>
    )
}

export default VehicleTypeWorked