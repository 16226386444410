import React from 'react';
import '../../Styles/styles.css';

function BusinessInformation(props) {
    const { handler } = props;
    return (
        <>
            <div>
                <br />
                <hr />
                <h2>Business information</h2>
                <input type="text" className="column-hundred" placeholder="Business address" name="Business address" onChange={(event) => { handler('address', event.target.value) }} />
                <br />
                <input type="text" className="column-thirty" placeholder="City" name="City" onChange={(event) => { handler('city', event.target.value) }} />
                <input type="text" className="column-thirty" placeholder="State" name="State" onChange={(event) => { handler('state', event.target.value) }} />
                <input type="text" className="column-thirty" placeholder="Zip code" name="Zip code" onChange={(event) => { handler('zip', event.target.value) }} />
            </div>
        </>
    )
}

export default BusinessInformation