import React, { useState, useEffect, useRef } from 'react';
import BusinessDetails from './form-components/BusinessDetails';
import PrimaryContact from './form-components/PrimaryContact';
import BusinessInformation from './form-components/BusinessInformation';
import StoreContact from './form-components/StoreContact';
import PassengerTires from './form-components/PassengerTires';
import SuvTires from './form-components/SuvTires';
import ServiceOffered from './form-components/ServicesOffered';
import { Catalogues, BalanceType, TireChangeType, VehicleTypeWorked, ConfirmLocation } from '../data/mock';
import VehicleTypeWorkedOn from './form-components/VehicleTypeWorked';
import AboutThisStore from './form-components/AboutThisStore';
import MoreThanOneInstallation from './form-components/MoreThanOneInstallation';
import HoursOfOperation from './form-components/HoursOfOperation';
import FlotationTires from './form-components/FlotationTires';
import CustomWheels from './form-components/CustomWheels';
import TrailerTires from './form-components/TrailerTires';
import '../Styles/styles.css';
import emailjs from 'emailjs-com';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ReCAPTCHA from "react-google-recaptcha";

function DataCollect() {

    const [businessDetailsData, setBusinessDetailsData] = useState({
        businessName: '',
        DBA: '',
        businessType: 'Business Type',
        installOption: 'Install options',
        website: ''
    });
    const [primaryContactData, setPrimaryContactData] = useState({
        fname: '',
        lname: '',
        jobTitle: '',
        contactNumber: '',
        email: ''
    });
    const [businessInformationData, setBusinessInformationData] = useState({
        address: '',
        city: '',
        state: '',
        zip: ''
    });
    const [storeContactData, setStoreContactData] = useState({
        email: '',
        phone_primary: '',
        phone_desk: '',
        sms: ''
    });

    const [hoursOfOperation, setHoursOfOperation] = useState({
        monday: {
            from: 'Closed',
            to: 'Closed'
        },
        tuesday: {
            from: 'Closed',
            to: 'Closed'
        },
        wednesday: {
            from: 'Closed',
            to: 'Closed'
        },
        thursday: {
            from: 'Closed',
            to: 'Closed'
        },
        friday: {
            from: 'Closed',
            to: 'Closed'
        },
        saturday: {
            from: 'Closed',
            to: 'Closed'
        },
        sunday: {
            from: 'Closed',
            to: 'Closed'
        }
    })
    const [openModal, setOpenModal] = useState(false);
    const [mailStatus, setMailStatus] = useState({
        status: true,
        successMessage: 'Thank you. We will be in touch soon',
        failureMessage: null
    });
    const [disabledBtn, setDisabledBtn] = useState(true);
    const businessDetailsHandler = (keyName, value) => {
        setBusinessDetailsData({ ...businessDetailsData, [keyName]: value });
    }
    const primaryContactHandler = (keyName, value) => {
        setPrimaryContactData({ ...primaryContactData, [keyName]: value });
    }
    const businessInformationHandler = (keyName, value) => {
        setBusinessInformationData({ ...businessInformationData, [keyName]: value });
    }
    const storeContactHandler = (keyName, value) => {
        setStoreContactData({ ...storeContactData, [keyName]: value });
    }
    const hoursOfOperationHandler = (keyName, type, value) => {
        setHoursOfOperation({
            ...hoursOfOperation, [keyName]: {
                ...hoursOfOperation[keyName],
                [type]: value
            }
        })
    };

    const [listServiceOffered, setListServiceOffered] = useState([]);
    const [isCheckServiceOffered, setIsCheckServiceOffered] = useState([]);

    const [listBalanceType, setListBalanceType] = useState([]);
    const [listTireChangeType, setTireChangeType] = useState([]);
    const [listVehicleTypeWorked, setVehicleTypeWorked] = useState([]);
    const [isCheckSVehicleTypeWorked, setIsCheckVehicleTypeWorked] = useState([]);

    const [serviceOfferedData, setserviceOfferedData] = useState({
        other_amenity_one: '',
        other_amenity_two: '',
        other_amenity_three: '',
        balance_type: '',
        tire_Change_type: '',
        isCheckServiceOffered: isCheckServiceOffered
    });
    const [aboutThisStoredata, setAboutThisStoredata] = useState({
        aboutStore: ''
    });
    const [locationInTotal, setlocationInTotal] = useState({
        total: ''
    });
    const [listConfirmLocation, setListConfirmLocation] = useState([]);
    const [isChecklistConfirmLocation, setIsChecklistConfirmLocation] = useState([]);

    const abouThisStoreHandler = (keyName, value) => {
        setAboutThisStoredata({ ...aboutThisStoredata, [keyName]: value });
    }
    const totalLocationHandler = (keyName, value) => {
        setlocationInTotal({ ...locationInTotal, [keyName]: value });
    }

    const [trailerTirePrice, setTrailerTirePrice] = useState({
        thirteenInchTirePrice: '',
        fourteenInchTirePrice: '',
        fifteenInchTirePrice: '',
        sixteenInchTirePrice: ''
    });

    const trailerTiresHandler = (keyName, value) => {
        setTrailerTirePrice({ ...trailerTirePrice, [keyName]: value });
    }

    const [customTirePrice, setCustomTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const customWheelsHandler = (keyName, value) => {
        setCustomTirePrice({ ...customTirePrice, [keyName]: value });
    }

    const [flotationFirstTirePrice, setFlotationFirstTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [flotationSecondTirePrice, setFlotationSecondTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [flotationThirdTirePrice, setFlotationThirdTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [flotationFourthTirePrice, setFlotationFourthTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const firstFlotationPriceHandler = (keyName, value) => {
        setFlotationFirstTirePrice({ ...flotationFirstTirePrice, [keyName]: value });
    }

    const secondFlotationPriceHandler = (keyName, value) => {
        setFlotationSecondTirePrice({ ...flotationSecondTirePrice, [keyName]: value });
    }

    const thirdFlotationPriceHandler = (keyName, value) => {
        setFlotationThirdTirePrice({ ...flotationThirdTirePrice, [keyName]: value });
    }

    const fourthFlotationPriceHandler = (keyName, value) => {
        setFlotationFourthTirePrice({ ...flotationFourthTirePrice, [keyName]: value });
    }

    const [suvFirstTirePrice, setSUVFirstTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [suvSecondTirePrice, setSUVSecondTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [suvThirdTirePrice, setSUVThirdTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [suvFourthTirePrice, setSUVFourthTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const firstSUVPriceHandler = (keyName, value) => {
        setSUVFirstTirePrice({ ...suvFirstTirePrice, [keyName]: value });
    }

    const secondSUVPriceHandler = (keyName, value) => {
        setSUVSecondTirePrice({ ...suvSecondTirePrice, [keyName]: value });
    }

    const thirdSUVPriceHandler = (keyName, value) => {
        setSUVThirdTirePrice({ ...suvThirdTirePrice, [keyName]: value });
    }

    const fourthSUVPriceHandler = (keyName, value) => {
        setSUVFourthTirePrice({ ...suvFourthTirePrice, [keyName]: value });
    }

    const [passengerFirstTirePrice, setPassengerFirstTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [passengerSecondTirePrice, setPassengerSecondTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [passengerThirdTirePrice, setPassengerThirdTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [passengerFourthTirePrice, setPassengerFourthTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const [passengerFifthTirePrice, setPassengerFifthTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const firstPassengerHandler = (keyName, value) => {
        setPassengerFirstTirePrice({ ...passengerFirstTirePrice, [keyName]: value });
    }

    const secondPassengerHandler = (keyName, value) => {
        setPassengerSecondTirePrice({ ...passengerSecondTirePrice, [keyName]: value });
    }

    const thirdPassengerHandler = (keyName, value) => {
        setPassengerThirdTirePrice({ ...passengerThirdTirePrice, [keyName]: value });
    }

    const fourthPassengerHandler = (keyName, value) => {
        setPassengerFourthTirePrice({ ...passengerFourthTirePrice, [keyName]: value });
    }

    const fifthPassengerHandler = (keyName, value) => {
        setPassengerFifthTirePrice({ ...passengerFifthTirePrice, [keyName]: value });
    }
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setDisabledBtn(true);
        emailjs.sendForm('service_z8oiuy9', 'template_hdj1w8k', form.current, 'user_hrD3ghbk1jFKi7p3rk3g0')
            .then((result) => {
                console.log(result.text);
                setOpenModal(true);
                setMailStatus({ ...mailStatus, status: true });
            }, (error) => {
                console.log(error.text);
                setOpenModal(true);
                setMailStatus({ ...mailStatus, status: false });
            });
    };

    const handleClickServiceOffered = (e) => {
        const { id, checked } = e.target;
        if (!checked) {
            setIsCheckServiceOffered(isCheckServiceOffered.filter((item) => item !== id));
        } else {
            setIsCheckServiceOffered([...isCheckServiceOffered, id]);
        }
    };

    const serviceOfferedHandler = (keyName, value) => {
        setserviceOfferedData({ ...serviceOfferedData, [keyName]: value });
    };


    const handleClickVehicleTypeWorked = (e) => {
        const { id, checked } = e.target;

        if (!checked) {
            setIsCheckVehicleTypeWorked(isCheckSVehicleTypeWorked.filter((item) => item !== id));
        } else {
            setIsCheckVehicleTypeWorked([...isCheckSVehicleTypeWorked, id]);
        }
    };

    const handleClickConfirmLocation = (e) => {
        const { id, checked } = e.target;
        if (!checked) {
            setIsChecklistConfirmLocation(isChecklistConfirmLocation.filter((item) => item !== id));
        } else {
            setIsChecklistConfirmLocation([...isChecklistConfirmLocation, id]);
        }
    };
    const onChangeCaptcha = (value) => {
        setDisabledBtn(false);
        console.log("Captcha value:", value);
    }

    useEffect(() => {
        setListServiceOffered(Catalogues);
    }, [listServiceOffered]);

    useEffect(() => {
        setListBalanceType(BalanceType);
    }, [listBalanceType]);

    useEffect(() => {
        setTireChangeType(TireChangeType);
    }, [listTireChangeType]);


    useEffect(() => {
        setVehicleTypeWorked(VehicleTypeWorked);
    }, [listVehicleTypeWorked]);

    useEffect(() => {
        setListConfirmLocation(ConfirmLocation);
    }, [listConfirmLocation]);

    useEffect(() => {
        setserviceOfferedData({ ...serviceOfferedData, isCheckServiceOffered: isCheckServiceOffered });
    }, [isCheckServiceOffered]);

    return (
        <>
            <div className='content-margin'>
                <form ref={form} onSubmit={sendEmail}>
                    <BusinessDetails handler={businessDetailsHandler} />
                    <PrimaryContact handler={primaryContactHandler} />
                    <BusinessInformation handler={businessInformationHandler} />
                    <StoreContact handler={storeContactHandler} />
                    <HoursOfOperation handler={hoursOfOperationHandler} />
                    <PassengerTires
                        firstHandler={firstPassengerHandler}
                        secondHandler={secondPassengerHandler}
                        thirdHandler={thirdPassengerHandler}
                        fourthHandler={fourthPassengerHandler}
                        fifthHandler={fifthPassengerHandler}
                    />
                    <SuvTires
                        firstHandler={firstSUVPriceHandler}
                        secondHandler={secondSUVPriceHandler}
                        thirdHandler={thirdSUVPriceHandler}
                        fourthHandler={fourthSUVPriceHandler}
                    />
                    <FlotationTires
                        firstHandler={firstFlotationPriceHandler}
                        secondHandler={secondFlotationPriceHandler}
                        thirdHandler={thirdFlotationPriceHandler}
                        fourthHandler={fourthFlotationPriceHandler}

                    />
                    <CustomWheels handler={customWheelsHandler} />
                    <TrailerTires handler={trailerTiresHandler} />
                    <ServiceOffered
                        list={listServiceOffered}
                        handleRadioBtnClick={handleClickServiceOffered}
                        isCheck={isCheckServiceOffered}
                        dataForBalanceTypeDropDown={listBalanceType}
                        handleInputTypeChange={serviceOfferedHandler}
                        dataForTireChangeTypeDropDown={listTireChangeType}
                    />
                    <VehicleTypeWorkedOn
                        list={listVehicleTypeWorked}
                        handleRadioBtnClick={handleClickVehicleTypeWorked}
                        isCheck={isCheckSVehicleTypeWorked}
                    />
                    <AboutThisStore handler={abouThisStoreHandler} />
                    <MoreThanOneInstallation
                        list={listConfirmLocation}
                        handlerTypeText={totalLocationHandler}
                        handleRadioBtnClick={handleClickConfirmLocation}
                        isCheck={isChecklistConfirmLocation}

                    />
                    <ReCAPTCHA
                        sitekey="6LceslAeAAAAAO3dmvhjgn7RiNdL-c-G7pU5-XDQ"
                        onChange={onChangeCaptcha}
                    />
                    <button type="submit" disabled={disabledBtn} className='submit-button'>Submit</button> 
                </form>
                <Popup open={openModal} modal className='border-content'>
                    {() => (
                        <>
                            <div className="modal">
                                <button className="close " onClick={() => setOpenModal(false)}>
                                    &times;
                                </button>
                                <div className="header">
                                    <h2>
                                        {mailStatus.status ? 'Success' : 'Failure'} </h2> </div>
                                <div className="content">
                                    {' '}
                                    {mailStatus.status ? mailStatus.successMessage : mailStatus.failureMessage}
                                </div>
                                <div className="actions">

                                    <button className="close-popup-button" onClick={() => setOpenModal(false)}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </Popup>
            </div>
        </>
    )
}

export default DataCollect
