import React from 'react';
import '../DataCollect.css';
import '../../Styles/styles.css';

function TirePriceDetails(props) {
    const { handler, placeholders, handlerHolders, aspectRatio, type, floatDetails } = props;
    return (
        <div className="row-header price-section">
            <>
                <div className="main-div">
                    <div className="aspect-ratio">
                        <div className="aspect-text">{aspectRatio}</div>
                    </div>
                    <div className="diameter price-fields">
                        <div className="input-field">
                            <input type="text" className="installFees column-fifteen" placeholder={placeholders[0]} 
                            name={ type === 'flotationTires' ? floatDetails?.[0] :type+aspectRatio+placeholders[0]} 
                            onChange={(event) => { handler(handlerHolders[0], event.target.value) }} />
                        </div>
                        <div className="input-field">
                            <input type="text" className="installFees column-fifteen" placeholder={placeholders[1]} 
                             name={ type === 'flotationTires' ? floatDetails?.[1] :type+aspectRatio+placeholders[1]} 
                             onChange={(event) => { handler(handlerHolders[1], event.target.value) }} />
                        </div>
                        <div className="input-field">
                            <input type="text" className="installFees column-fifteen" placeholder={placeholders[2]} 
                             name={ type === 'flotationTires' ? floatDetails?.[2] :type+aspectRatio+placeholders[2]} 
                             onChange={(event) => { handler(handlerHolders[2], event.target.value) }} />
                        </div>
                        <div className="input-field">
                            <input type="text" className="installFees column-fifteen" placeholder={placeholders[3]} 
                             name={ type === 'flotationTires' ? floatDetails?.[3] :type+aspectRatio+placeholders[3]} 
                             onChange={(event) => { handler(handlerHolders[3], event.target.value) }} />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default TirePriceDetails
