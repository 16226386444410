import React from "react";
import Checkbox from "./Checkbox";
import '../../Styles/styles.css'


const ServiceOffered = (props) => {
    const { list, handleRadioBtnClick, isCheck, dataForBalanceTypeDropDown, handleInputTypeChange, dataForTireChangeTypeDropDown } = props;
    const catalog = list.map(({ id, name }) => {
        return (
            <>
                <Checkbox
                    key={id.toString()}
                    type="checkbox"
                    name={name}
                    id={id}
                    handleRadioBtnClick={handleRadioBtnClick}
                    isChecked={isCheck.includes(id)}
                    classes={"container column"}
                />



            </>
        );
    });

    const optionsForBalacnerType = dataForBalanceTypeDropDown.map((item) => {
        return (<option value={item.name} key={item.id}>{item.name}</option>)
    })
    const optionsForTireChangeTypew = dataForTireChangeTypeDropDown.map((item) => {
        return (<option value={item.name} key={item.id}>{item.name}</option>)
    })

    return (
        <div>
            <br />
            <hr />
            <h2>Service Offered</h2>
            <h3>Check all that apply</h3>
            <div className="row">
                {catalog}
            </div>

            <div>
                <input type="text" placeholder="Other amenity 1" className="column-thirty " name="Other amenity 1" onChange={(event) => { handleInputTypeChange('other_amenity_one', event.target.value) }} />
                <input type="text" placeholder="Other amenity 2" className="column-thirty " name="Other amenity 2" onChange={(event) => { handleInputTypeChange('other_amenity_two', event.target.value) }} />
                <input type="text" placeholder="Other amenity 3" className="column-thirty " name="Other amenity 3" onChange={(event) => { handleInputTypeChange('other_amenity_three', event.target.value) }} />

                <select defaultValue="Balancer Type" className="column-fifty " name="Balancer Type" onChange={(event) => handleInputTypeChange('balance_type', event.target.value)}  >
                    {optionsForBalacnerType}
                </select>
                <select defaultValue="Tire Change Type" className="column-fifty " name="Tire Change Type" onChange={(event) => handleInputTypeChange('tire_Change_type', event.target.value)}  >
                    {optionsForTireChangeTypew}
                </select>
            </div>
        </div>
    );
};


export default ServiceOffered;