import React from 'react';
import TirePriceDetails from './TirePriceDetails';
import GenericInstallationFees from './GenericInstallationFees';
import '../DataCollect.css';

function TrailerTires(props) {
    const { handler } = props;

    const placeholderTires = ['Price for 13 inch', 'Price for 14 inch', 'Price for 15 inch', 'Price for 16 inch'];
    const handlersTires = ['thirteenInchTirePrice', 'fourteenInchTirePrice', 'fifteenInchTirePrice', 'sixteenInchTirePrice'];
    const type ='trailerTires';

    return (
        <>
            <br />
            <hr />
            <h2>Trailer Tires</h2>
            <div className="row-header">
                <div className="column-fifty-label display-none"></div>
                <div className="column-fifty-label hide-in-mob"><b>Diameter</b></div>
            </div>
            <TirePriceDetails handler={handler} placeholders={placeholderTires} handlerHolders={handlersTires} aspectRatio="Mounting Rates (per tire)" type={type} />
            <h3>Trailer tire installation fees</h3>
            <GenericInstallationFees flatTire={false} tpms={true} rubberTire={true} type={type} />
        </>
    )
}

export default TrailerTires
