import React from 'react';
import TirePriceDetails from './TirePriceDetails';
import GenericInstallationFees from './GenericInstallationFees';
import '../DataCollect.css';

function PassengerTires(props) {
    const { firstHandler, secondHandler, thirdHandler, fourthHandler, fifthHandler } = props;

    const placeholderFirstTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const placeholderSecondTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const placeholderThirdTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const placeholderFourthTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];

    const handlersTiresFirstCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresSecondCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresThirdCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresFourthCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const type ='passengerTires';
    return (
        <>
            <br />
            <hr />
            <h2>Installation Prices for passenger tires</h2>
            <h2>Mount and Balance Rates (per tire)</h2>
            <div className="row-header">
                <label className="column-fifty-label"><b>Aspect ratio</b></label>
                <label className="column-fifty-label hide-in-mob"><b>Diameter</b></label>
            </div>
            <TirePriceDetails handler={firstHandler} placeholders={placeholderFirstTirePrice} handlerHolders={handlersTiresFirstCate} aspectRatio="35 and lower" type={type} />
            <TirePriceDetails handler={secondHandler} placeholders={placeholderSecondTirePrice} handlerHolders={handlersTiresSecondCate} aspectRatio="40-45" type={type} />
            <TirePriceDetails handler={thirdHandler} placeholders={placeholderThirdTirePrice} handlerHolders={handlersTiresThirdCate} aspectRatio="50-55" type={type} />
            {/* <TirePriceDetails handler={fourthHandler} placeholders={placeholderFourthTirePrice} handlerHolders={handlersTiresFourthCate} aspectRatio="60 and higher" type={type} /> */}
            <div className="row-header price-section">
                <>
                    <div className="main-div">
                        <div className="aspect-ratio">
                            <div className="aspect-text">50-55</div>
                        </div>
                        <div className="diameter price-fields">
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFourthTirePrice[0]} name="PassengerTires1" onChange={(event) => { fourthHandler(handlersTiresFourthCate[0], event.target.value) }} />
                            </div>
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFourthTirePrice[1]} name="PassengerTires2" onChange={(event) => { fourthHandler(handlersTiresFourthCate[1], event.target.value) }} />
                            </div>
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFourthTirePrice[2]} name="PassengerTires3" onChange={(event) => { fourthHandler(handlersTiresFourthCate[2], event.target.value) }} />
                            </div>
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFourthTirePrice[3]} name="PassengerTires4" onChange={(event) => { fourthHandler(handlersTiresFourthCate[3], event.target.value) }} />
                            </div>
                        </div>
                    </div>
                </>
            </div>
            <h3>Installation Fees (per tire)</h3>
            <GenericInstallationFees flatTire={true} tpms={false} rubberTire={true} type={type} />
            <h3>Wheels & Tires Package Add On Fee (per tire)</h3>
            <h4>* Will be added to total mount & balance fee</h4>
            <div className="row-header">
                <label className="column-fifty-label display-none"><b>Aspect ratio</b></label>
                <label className="column-fifty-label"><b>Wheel size</b></label>
            </div>
            <TirePriceDetails handler={fifthHandler} placeholders={placeholderFourthTirePrice} handlerHolders={handlersTiresFourthCate} aspectRatio="Price per tire + wheel" type={type} />
        </>
    )
}

export default PassengerTires
