export const Catalogues = [
  {
    id: "01",
    name: "Air Conditioning"
  },
  {
    id: "02",
    name: "Audio Installation"
  },
  {
    id: "03",
    name: "Fluids"
  },
  {
    id: "04",
    name: "Wifi"
  },
  {
    id: "05",
    name: "Run Flat Certified"
  },
  {
    id: "06",
    name: "Road Force Balancing"
  },
  {
    id: "07",
    name: "Suspension Lower"
  },
  {
    id: "08",
    name: "Wiper Blades"
  },
  {
    id: "09",
    name: "Diesel"
  },
  {
    id: "10",
    name: "Exhaust"
  },
  {
    id: "11",
    name: "Shocks/struts"
  },
  {
    id: "12",
    name: "Full Mechanical"
  },
  {
    id: "13",
    name: "Accepts Appointments"
  },
  {
    id: "14",
    name: "Bed Liner"
  },
  {
    id: "15",
    name: "Shuttle Service"
  },
  {
    id: "16",
    name: "Spanish Speakers"
  },
  {
    id: "17",
    name: "Nitrogen Inflation"
  },
  {
    id: "18",
    name: "Smog Check"
  },
  {
    id: "19",
    name: "Battery"
  },
  {
    id: "20",
    name: "Drive Train"
  },
  {
    id: "21",
    name: "Fuel System Service"
  },
  {
    id: "22",
    name: "TPMS"
  },
  {
    id: "23",
    name: "Preventative Maintenance"
  },
  {
    id: "24",
    name: "After Hours Key Drop"
  },
  {
    id: "25",
    name: "Brakes"
  },
  {
    id: "26",
    name: "Loaner Vehicles"
  },
  {
    id: "27",
    name: "ASE Certified"
  },
  {
    id: "28",
    name: "Oil Change"
  },
  {
    id: "29",
    name: "Inspections"
  },
  {
    id: "30",
    name: "Touchless Mounting"
  },
  {
    id: "31",
    name: "Cooling"
  },
  {
    id: "32",
    name: "Electrical"
  },
  {
    id: "33",
    name: "Install 20 inch and up tires"
  },
  {
    id: "34",
    name: "Tire Rotation"
  },
  {
    id: "35",
    name: "Alignment"
  },
  {
    id: "36",
    name: "Brake Upgrades"
  },
  {
    id: "37",
    name: "Vehicle Pick-up and Drop-off"
  },
  {
    id: "38",
    name: "BBB Accredited Business"
  },
  {
    id: "39",
    name: "Tune-up"
  },
  {
    id: "40",
    name: "Suspension Lift"
  },
  {
    id: "41",
    name: "Transmission"
  },
  {
    id: "42",
    name: "Diagnostic"
  },
  {
    id: "43",
    name: "Emissions"
  },
  {
    id: "44", name: "Radiator"
  }
  ,
  { id: "45", name: "Towing" }

];


export const BalanceType = [
  { id: "01", name: "Balancer Type" },
  { id: "02", name: "ROADFORCE_GSP9700" },
  { id: "03", name: "Xiaomi" },
  { id: "04", name: "QUICKMATCH_SYSTEM" },
  { id: "05", name: "SMARTWEIGHT_SYSTEM" },
  { id: "06", name: "MODEL_1000" },
  { id: "07", name: "MODEL_1175" },
  { id: "08", name: "EEWB_304D" },
  { id: "09", name: "EEWB_305D" },
  { id: "10", name: "BFH_1000" },
  { id: "11", name: "BFH_800" },
  { id: "12", name: "VPI_SYSTEM_IV" },
  { id: "13", name: "GEODYNA_OPTIMA" },
  { id: "14", name: "GEODYNA_9600P" },
  { id: "15", name: "GEODYNA_9300" },
  { id: "16", name: "BLUELIGHT" },
  { id: "17", name: "EM7280" },
  { id: "18", name: "EM8570" }
]

export const TireChangeType = [
  { id: "01", name: "Tire Changer Type (Select one)" },
  { id: "02", name: "AUTO_34" },
  { id: "03", name: "AUTO_28" },
  { id: "04", name: "TC_3700" },
  { id: "05", name: "TC_3300" },
  { id: "06", name: "TCX_575" },
  { id: "07", name: "TCX_535" },
  { id: "08", name: "PROGUARD_LEVERLESS" },
  { id: "09", name: "APX_90" },
  { id: "10", name: "APX_80" },
  { id: "11", name: "SERIES_9024E" },
  { id: "12", name: "SERIES_70X_SERIES" },
  { id: "13", name: "EEWH_315A" },
  { id: "14", name: "EEWH_311A" },
  { id: "15", name: "EEWH_310B" },
  { id: "16", name: "EEWH_306A" },
  { id: "17", name: "QUADRIGS_ATC_1000" },
  { id: "18", name: "PAGASUS_ATC_800" },
  { id: "19", name: "EHP_SYSTEM_V" },
  { id: "20", name: "EHP_SYSTEM_IV_AT_PLUS" },
  { id: "21", name: "MONTY_FA_1000" },
  { id: "22", name: "MONTY_UNIVERSAL_2" },
  { id: "23", name: "MONTY_3550" },
  { id: "24", name: "MONTY_3300_AT_PLUS" },
  { id: "25", name: "ARTIGLIO_MASTER_MI" },
  { id: "26", name: "ARTIGLIO_500" },
  { id: "27", name: "ARTIGLIO_50" },

]

export const VehicleTypeWorked = [
  { id: "01", name: "Truck/SUV" },
  { id: "02", name: "Off-Road" },
  { id: "03", name: "Domestic" },
  { id: "04", name: "Asian" },
  { id: "05", name: "Euro" },
  { id: "06", name: "Domestic Tuner" },
  { id: "07", name: "Asian Tuner" },
  { id: "08", name: "Euro Tuner" },
  { id: "09", name: "Lifted Trucks" },
  { id: "10", name: "Motorsport" }
]

export const ConfirmLocation = [
  { id: "01" , name:"Tirebuyer to call you to arrange"},
  { id: "02" , name:"Tirebuyer to send you a spreadsheet to complete"},
  { id: "03" , name:"Tirebuyer to send you a PDF form to complete for each location"},
  { id: "04" , name:"Send me another web form"},
]