import React from 'react';
import '../../Styles/styles.css';

function GenericInstallationFees(props) {
    const { flatTire, tpms, rubberTire, type } = props;
    return(
        <>
            <div>
                { rubberTire === true ? <input type="text" className="column-thirty" name={type+"Rubber valve stem fee"} placeholder="Rubber valve stem fee"/> : <input type="text" className="column-thirty" name={type+"High pressure valve stem fee"} placeholder="High pressure valve stem fee"/> }
                <input type="text" className="column-thirty" name={type+"Disposal fee"} placeholder="Disposal fee"/>
                { tpms === false ? <input type="text" className="column-thirty" name={type+"TPMS rebuild kit or reset fee"} placeholder="TPMS rebuild kit or reset fee"/> : '' }
                { flatTire === true ? <input type="text" className="column-thirty" name={type+"Run flat tire fee - enter NA if you do not install RF"} placeholder="Run flat tire fee - enter NA if you do not install RF"/> : '' }
                <input type="text" className="column-fifty-input-text" name={type+"Shop fee - flat fee or percentage of total"} placeholder="Shop fee - flat fee or percentage of total"/>
            </div>
        </>
    )
}

export default GenericInstallationFees