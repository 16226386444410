import React from 'react';
import Checkbox from "./Checkbox";
import '../../Styles/styles.css';

function MoreThanOneInstallation(props) {

    const { list, isCheck, handlerTypeText, handleRadioBtnClick } = props;
    const catalog = list.map(({ id, name }) => {
        return (
            <>
                <Checkbox
                    key={id.toString()}
                    type="checkbox"
                    name={name}
                    id={id}
                    handleRadioBtnClick={handleRadioBtnClick}
                    isChecked={isCheck.includes(id)}
                    classes={"container"}
                />
            </>
        );
    });
    return (
        <>
            <div>
                <br/>
                <hr/>
                <h2>More than one installation location:</h2>
                <div className="location-wrapper">
                    <input type="text" name="totallocation" className='column-fifty' placeholder="How many location in total : " onChange={(event) => { handlerTypeText('total', event.target.value) }} />
                </div>
                <h4>How would you like to confirm your other locations:</h4>
                {catalog}
            </div>
        </>
    )
}

export default MoreThanOneInstallation