import React from 'react';
import '../../Styles/styles.css';
function BusinessDetails(props) {
    const { handler } = props;
    const businessTypesData = ['Business Type', 'INC', 'LLC', 'Other'];
    const installOptionsData = ['Install options', 'Shop', 'Mobile', 'Both'];
    return (
        <>
            <div>
                <h2>Tirebuyer Partner Information Form</h2>
                <p>
                    This form can be used by either tire shops interested in becoming a Tirebuyer Partner for the first time, or by existing Tirebuyer partners looking to update their information. <strong>New partners should complete the form in its entirety.</strong> Existing partners should complete the required fields at the top (so we can identify you) and then only complete the fields for which your information is changing. 
                    All other fields can remain blank. If you have more than one location, complete this form for one location only. There is a question at the bottom of the form to indicate how you’d prefer us to obtain details for the remaining locations.
                </p>
                <div className="businessDetailsWrapper">
                    <input type="text" className="column-fifty-input-text" placeholder="Business Name" name="Business Name" onChange={(event)=>{handler('businessName', event.target.value)}} />
                    <input type="text" className="column-fifty-input-text" placeholder="DBA (if different)" name="DBA" onChange={(event)=>{handler('DBA', event.target.value)}} />
                    <select className="column-fifty" defaultValue="Business Type" name="Business Type" onChange={(event)=>{handler('businessType', event.target.value)}}>
                        {
                            businessTypesData.map(elem=> <option value={elem} key={elem}>{elem}</option>)
                        }
                    </select>
                    <select className="column-fifty" defaultValue="Install options" name="Install options" onChange={(event)=>{handler('installOption', event.target.value)}}>
                        {
                            installOptionsData.map(elem=> <option value={elem} key={elem}>{elem}</option>)
                        }
                    </select>
                    <input className="column-hundred" type="text" placeholder="Website" name="Website" onChange={(event)=>{handler('website', event.target.value)}} />
                </div>   
            </div>
        </>
    )
}

export default BusinessDetails