import React from 'react';
import '../../Styles/styles.css';

function StoreContact(props) {
    const { handler } = props;
    return (
        <>
            <div>
                <br />
                <hr />
                <h2>Store contact information</h2>
                <p>For receiving orders</p>
                <input type="email" className="column-hundred" placeholder="Email" name="Email" onChange={(event) => { handler('email', event.target.value) }} />
                <br />
                <input type="text" className="column-fifty-input-text" placeholder="Phone (Primary)" name="Phone (Primary)" onChange={(event) => { handler('phone_primary', event.target.value) }} />
                <input type="text" className="column-fifty-input-text" placeholder="Phone (Service desk)" name="Phone (Service desk)" onChange={(event) => { handler('phone_desk', event.target.value) }} />
                <br />
                <input type="text" className="column-fifty-input-text" placeholder="sms" name="sms" onChange={(event) => { handler('sms', event.target.value) }} />
            </div>
        </>
    )
}

export default StoreContact