import React from 'react';
import TirePriceDetails from './TirePriceDetails';
import GenericInstallationFees from './GenericInstallationFees';
import '../../Styles/styles.css';

function FlotationTires(props) {
    const { firstHandler, secondHandler, thirdHandler, fourthHandler } = props;

    const placeholderTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];

    const handlersTiresFirstCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresSecondCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresThirdCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresFourthCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const type ='flotationTires';
    const floatDetails = [
        ['row113', 'row117', 'row120', 'row121'],
        ['row213', 'row217', 'row220', 'row221'],
        ['row313', 'row317', 'row320', 'row321'],
        ['row413', 'row417', 'row420', 'row421']
    ]
    const aspectRatio = ['27" to 30"', '31" - 35"', '36" & Higher']
    return(
        <>
            <br />
            <hr />
            <h2>Light truck/Flotation tires</h2>
            <h2>Mount and Balance Rates (per tire)</h2>
            <div className="row-header">
                <label className="column-fifty-label"><b>Tire Diameter</b></label>
                <label className="column-fifty-label hide-in-mob"><b>Wheel Size</b></label>
            </div>
            <TirePriceDetails floatDetails={floatDetails[0]} handler={firstHandler} placeholders={placeholderTirePrice} handlerHolders={handlersTiresFirstCate} aspectRatio={aspectRatio[0]} type={type} />
            <TirePriceDetails floatDetails={floatDetails[1]} handler={secondHandler} placeholders={placeholderTirePrice} handlerHolders={handlersTiresSecondCate} aspectRatio={aspectRatio[1]} type={type} />
            <TirePriceDetails  floatDetails={floatDetails[2]} handler={thirdHandler} placeholders={placeholderTirePrice} handlerHolders={handlersTiresThirdCate} aspectRatio={aspectRatio[2]} type={type} />
            <h3>Installation Fees (per tire)</h3>
            <GenericInstallationFees flatTire={false} tpms={false} rubberTire={false} type={type} />
            <h3>Wheels & Tires Package Add On Fee (per tire)</h3>
            <h4>* Will be added to total mount & balance fee</h4>
            <div className="row-header">
                <label className="column-fifty-label display-none"><b>Aspect ratio</b></label>
                <label className="column-fifty-label"><b>Wheel size</b></label>
            </div>
            <TirePriceDetails floatDetails={floatDetails[3]} handler={fourthHandler} placeholders={placeholderTirePrice} handlerHolders={handlersTiresFourthCate} aspectRatio="Price per tire + wheel" type={type} />
        </>
    )
}

export default FlotationTires
