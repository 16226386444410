import React from 'react';
import TirePriceDetails from './TirePriceDetails';
import GenericInstallationFees from './GenericInstallationFees';
import '../DataCollect.css';
import '../../Styles/styles.css';

function CustomWheels(props) {
    const { handler } = props;

    const placeholderFirstTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const handlersTiresFirstCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const type ='customWheels';
    
    return(
        <>
            <br />
            <hr />
            <h2>Custom wheels only</h2>
            <h3>*Customer purchases tires from you or using their existing tires</h3>
           
            <div className="row-header">
                <label className="column-fifty-label display-none"><b>Aspect ratio</b></label>
                <label className="column-fifty-label hide-in-mob"><b>Diameter</b></label>
            </div>
            {/* <TirePriceDetails handler={handler} placeholders={placeholderFirstTirePrice} handlerHolders={handlersTiresFirstCate} aspectRatio="Mount & Balance Rates (per tire)" type={type} /> */}
            <div className="row-header price-section">
                <>
                    <div className="main-div">
                        <div className="aspect-ratio">
                            <div className="aspect-text">Mount & Balance Rates (per tire)</div>
                        </div>
                        <div className="diameter price-fields">
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFirstTirePrice[0]} name="CustomWheels1" onChange={(event) => { handler(handlersTiresFirstCate[0], event.target.value) }} />
                            </div>
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFirstTirePrice[1]} name="CustomWheels2" onChange={(event) => { handler(handlersTiresFirstCate[1], event.target.value) }} />
                            </div>
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFirstTirePrice[2]} name="CustomWheels3" onChange={(event) => { handler(handlersTiresFirstCate[2], event.target.value) }} />
                            </div>
                            <div className="input-field">
                                <input type="text" className="installFees column-fifteen" placeholder={placeholderFirstTirePrice[3]} name="CustomWheels4" onChange={(event) => { handler(handlersTiresFirstCate[3], event.target.value) }} />
                            </div>
                        </div>
                    </div>
                </>
            </div>
            <h3>Wheel installation fees</h3>
            <GenericInstallationFees flatTire={false} tpms={false} rubberTire={false} type={type} />
        </>
    )
}

export default CustomWheels
