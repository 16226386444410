import React, { useState } from 'react';
import TirePriceDetails from './TirePriceDetails';
import GenericInstallationFees from './GenericInstallationFees';
import '../DataCollect.css';

function SuvTires(props) {
    const { firstHandler, secondHandler, thirdHandler, fourthHandler } = props;

    const [primaryTirePrice, setPrimaryTirePrice] = useState({
        thirteenInchTirePrice: '',
        seventeenInchTirePrice: '',
        twentyInchTirePrice: '',
        twentyOneInchTirePrice: ''
    });

    const firstPriceHandler = (keyName, value) => {
        setPrimaryTirePrice({ ...primaryTirePrice, [keyName]: value });
    }

    const secondPriceHandler = (keyName, value) => {
        setPrimaryTirePrice({ ...primaryTirePrice, [keyName]: value });
    }

    const thirdPriceHandler = (keyName, value) => {
        setPrimaryTirePrice({ ...primaryTirePrice, [keyName]: value });
    }

    const fourthPriceHandler = (keyName, value) => {
        setPrimaryTirePrice({ ...primaryTirePrice, [keyName]: value });
    }
    const placeholderFirstTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const placeholderSecondTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const placeholderThirdTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];
    const placeholderFourthTirePrice = ['Price for 13-16 inch', 'Price for 17-19 inch', 'Price for 20 inch', 'Price for 21-24 inch'];

    const handlersTiresFirstCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresSecondCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresThirdCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const handlersTiresFourthCate = ['thirteenInchTirePrice', 'seventeenInchTirePrice', 'twentyInchTirePrice', 'twentyOneInchTirePrice'];
    const type ='SUVTires';
    return(
        <>
            <br />
            <hr />
            <h2>Light truck/SUV tires</h2>
            <h2>Mount and Balance Rates (per tire)</h2>
            <div className="row-header">
                <label className="column-fifty-label"><b>Aspect ratio</b></label>
                <label className="column-fifty-label hide-in-mob"><b>Diameter</b></label>
            </div>
            <TirePriceDetails handler={firstHandler} placeholders={placeholderFirstTirePrice} handlerHolders={handlersTiresFirstCate} aspectRatio="45 and lower" type={type} />
            <TirePriceDetails handler={secondHandler} placeholders={placeholderSecondTirePrice} handlerHolders={handlersTiresSecondCate} aspectRatio="50-55" type={type} />
            <TirePriceDetails handler={thirdHandler} placeholders={placeholderThirdTirePrice} handlerHolders={handlersTiresThirdCate} aspectRatio="60 and higher" type={type} />
            <h3>Installation Fees (per tire)</h3>
            <GenericInstallationFees flatTire={false} tpms={false} rubberTire={false} type={type} />
            <h3>Wheels & Tires Package Add On Fee (per tire)</h3>
            <h4>* Will be added to total mount & balance fee</h4>
            <div className="row-header">
                <label className="column-fifty-label display-none"><b>Aspect ratio</b></label>
                <label className="column-fifty-label"><b>Wheel size</b></label>
            </div>
            <TirePriceDetails handler={fourthHandler} placeholders={placeholderFourthTirePrice} handlerHolders={handlersTiresFourthCate} aspectRatio="Price per tire + wheel" type={type} />
        </>
    )
}

export default SuvTires