import React from 'react';
import '../../Styles/styles.css';

function PrimaryContact(props) {
    const { handler } = props;
    return (
        <>
            <div>
                <br />
                <hr />
                <h2>Primary contact</h2>
                <input type="text" className="column-thirty" placeholder="First name" name="First name" onChange={(event) => { handler('fname', event.target.value) }} />
                <input type="text" className="column-thirty" placeholder="Last name" name="Last name" onChange={(event) => { handler('lname', event.target.value) }} />
                <input type="text" className="column-thirty" placeholder="Primary Contact Job Title" name="Primary Contact Job Title" onChange={(event) => { handler('jobTitle', event.target.value) }} />
                <br />
                <input type="text" className="column-hundred" placeholder="Contact number" name="Contact number" onChange={(event) => { handler('contactNumber', event.target.value) }} />
                <br />
                <input type="text" className="column-hundred" placeholder="Primary contact email" name="Primary contact email" onChange={(event) => { handler('email', event.target.value) }} />
            </div>
        </>
    )
}

export default PrimaryContact